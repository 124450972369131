import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const DriverUserManual = () => {
    const sections = [
        {
            title: "1. Overview",
            content: `
                The tool allows users to:
                - Upload SPSS files containing survey or statistical data.
                - Run analyses (e.g., Linear Regression or Kruskal Analysis).
                - Filter data using specific binary fields.
                - Handle missing values with customizable options.
                - Export results to Excel format.
            `,
        },
        {
            title: "2. Getting Started",
            content: `
                ### Uploading SPSS Data
                1. Locate the Upload Section:
                   - The SPSS upload section is located in the center of the interface with the title "Upload SPSS Data for Driver Analysis".
                2. Select an SPSS File:
                   - Click the "Select SPSS File" field to browse and select your .sav file.
                3. Upload the File:
                   - Click the "Upload" button. During the upload, a spinner will indicate progress.
                   - If successful, a success message will appear.
                   - If an error occurs, check the error message displayed in red.
                4. View Uploaded Files:
                   - Previously uploaded files are listed in the sidebar on the right.
                   - Click on a file to load its data into the system.
            `,
        },
        {
            title: "3. Running an Analysis",
            content: `
                ### Select Analysis Parameters
                1. Choose Analysis Type:
                   - Select from the dropdown under "Analysis Type":
                     - Linear Regression
                     - Kruskal Analysis
                2. Set Dependent Variable:
                   - Choose the variable that represents the outcome you want to analyze.
                3. Set Independent Variables:
                   - Select one or more variables that affect the dependent variable.
                   - Hold down the Ctrl or Cmd key to select multiple options.
                4. Apply Filters (Optional):
                   - Use binary columns to filter your data (e.g., male respondents).
                5. Handle Missing Values:
                   - Choose how to deal with missing data:
                     - Drop NA: Remove rows with missing values.
                     - Replace with Mean: Replace missing values with the mean.
                     - Replace with Median: Replace missing values with the median.
                     - Default: Do not modify missing values.

                ### Run the Analysis
                - Click the "Run Analysis" button.
                - During the analysis, a success message will indicate progress.
                - Once completed, results will display in a table format.
            `,
        },
        {
            title: "4. Results and Interpretation",
            content: `
                The results table provides:
                - Driver: Name or label of the variable.
                - Coefficient: Indicates the impact of the variable.
                - Absolute Coefficient: Absolute value of the coefficient.
                - Coefficient (%): Percentage contribution of the variable.
                - R-Squared: Indicates the goodness of fit for regression analyses.

                The sample size used in the analysis is displayed at the top of the results table under "Samples considered".
            `,
        },
        {
            title: "5. Exporting Results",
            content: `
                - After the analysis is complete, click the "Export to Excel" button above the results table.
                - A .xlsx file containing the analysis results will be downloaded to your system.
                - Open the file in any spreadsheet application to view or share the results.
            `,
        },
        {
            title: "6. Key Features and Definitions",
            content: `
                ### Filters
                - Filters are binary variables (e.g., gender, age group) that you can use to subset the data.

                ### NaN Handling
                - Missing values can affect the analysis. Use NaN handling options to specify how these values should be processed.
            `,
        },
        {
            title: "7. Troubleshooting",
            content: `
                Common Errors:
                - "No file uploaded.": Ensure a .sav file is selected before clicking Upload.
                - "Error uploading file.": Check that the file is in .sav format and try again.
                - "Failed to load file columns.": Ensure the uploaded file is formatted correctly.
                - "File ID is missing.": Upload a file and select dependent/independent variables before proceeding.
                - "No results to export.": Run the analysis before exporting results.
            `,
        },
        {
            title: "8. Contact and Support",
            content: `
                If you encounter issues or have questions, please contact support at support@4sightoperations.in.
            `,
        },
    ];

    return (
        <Container className="mt-4">
            <Row>
                <Col>
                    <h1 className="text-center mb-4">Driver Analysis Tool - User Manual</h1>
                </Col>
            </Row>
            {sections.map((section, index) => (
                <Row key={index} className="mb-3">
                    <Col>
                        <Card>
                            <Card.Header as="h5">{section.title}</Card.Header>
                            <Card.Body>
                                <Card.Text style={{ whiteSpace: "pre-line" }}>
                                    {section.content}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ))}
            <Row>
                <Col className="text-center">
                    <Button variant="primary" onClick={() => window.print()}>
                        Print Manual
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default DriverUserManual;
