import React, { useState } from 'react';
import axios from 'axios';

function Chatbot() {
    const [text, setText] = useState('');
    const [query, setQuery] = useState('');

    const handleQueryChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSubmit = () => {
        axios.post('https://4sightoperations.in/chatbot/chatbot/', { user_input: query })
            .then(response => {
                const responseData = response.data.data;  // Assuming data is returned as 'data' field
                const aiResponse = response.data.ai_response;  // AI response text
                const newText = `${text}\n\nQuery: ${query}\nResponse: ${JSON.stringify(responseData)}\nAI Response: ${aiResponse}\n`;
                setText(newText);
                setQuery('');
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    return (
        <div style={{ paddingTop: '10px' }}>
            <div style={{ width: '100%', height: '400px', margin: 'auto', padding: '10px', paddingTop: '5px' }}>
                <textarea
                    id="textResult"
                    value={text}
                    readOnly // Make the textarea read-only
                    style={{ width: '100%', height: '100%', borderRadius: '20px', resize: 'none' }}
                />
            </div>

            <div style={{ marginLeft: '20px', paddingTop: '5px' }}>
                <input
                    type="text"
                    id="textInput"
                    value={query}
                    onChange={handleQueryChange}
                    placeholder="Type something..."
                    style={{ width: '93%', height: '30px', resize: 'none', marginBottom: '10px', borderRadius: '5px' }}
                />
                <button onClick={handleSubmit} style={{ height: '36px', marginLeft: '10px' }}>Submit</button>
            </div>
        </div>
    );
}

export default Chatbot;
