import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const DriverAnalysisGuide = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h2>Driver Analysis Guide</h2>
          <p>
            Driver Analysis is a statistical technique used to understand the influence of independent variables (drivers) 
            on a dependent variable. It helps in identifying which factors contribute the most to the outcome you're analyzing.
          </p>
          
          <h3>Types of Analysis</h3>
          <ul>
            <li>
              <strong>Linear Regression:</strong> 
              Best used when analyzing relationships between continuous variables. It assumes a linear relationship 
              between the dependent and independent variables.
            </li>
            <li>
              <strong>Kruskal Analysis:</strong> 
              Non-parametric method used for ranking variables and comparing groups when data does not meet parametric assumptions.
            </li>
          </ul>
          
          <h3>When to Use Regression Analysis?</h3>
          <ul>
            <li>
              Use regression when your dependent variable is numeric and continuous, such as customer satisfaction scores.
            </li>
            <li>
              It is ideal for survey data to identify key drivers impacting an outcome metric.
            </li>
            <li>
              Regression requires a minimum amount of data; avoid running it on small datasets.
            </li>
          </ul>
          
          <h3>How to Interpret Results?</h3>
          <ul>
            <li>
              <strong>Coefficient:</strong> Indicates the strength and direction of the relationship between an independent variable and the dependent variable.
            </li>
            <li>
              <strong>R-Squared:</strong> Represents the proportion of variance explained by the model. A higher value indicates better explanatory power.
            </li>
            <li>
              <strong>Absolute Coefficient:</strong> Helps rank the drivers by their relative importance.
            </li>
          </ul>
          
          <h3>Question Types to Analyze</h3>
          <ul>
            <li>
              <strong>Customer Feedback Surveys:</strong> Questions like "Rate your satisfaction with X" on a numeric scale.
            </li>
            <li>
              <strong>Market Research Data:</strong> Data related to preferences, purchasing habits, or brand perception.
            </li>
            <li>
              <strong>Employee Engagement Surveys:</strong> Variables like job satisfaction or team collaboration scores.
            </li>
          </ul>
          
          <h3>Steps to Run Analysis</h3>
          <ol>
            <li>Upload your SPSS data file using the <strong>Upload SPSS Data</strong> section.</li>
            <li>Select your dependent variable (the outcome you want to analyze).</li>
            <li>Select the independent variables (drivers you suspect influence the outcome).</li>
            <li>Choose the type of analysis (e.g., Linear Regression or Kruskal Analysis).</li>
            <li>Handle missing values using options like dropping or replacing with mean/median.</li>
            <li>Run the analysis and interpret the results using the coefficient and R-Squared values.</li>
            <li>Export the results to Excel for further use if needed.</li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
};

export default DriverAnalysisGuide;
