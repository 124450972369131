import React, { useState, useEffect } from "react";
import { Form, Button, ListGroup, Spinner, Alert } from "react-bootstrap";
import axios from "axios"; // Axios for API requests
import { useLocation } from "react-router-dom";

export const JTDiscussionPage = () => {
  const location = useLocation();
  const { segmentId } = location.state || {}; // Extract segmentId from URL params
  const email = localStorage.getItem('username'); // Get the email stored in localStorage

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null); // To store the current user's actual username
  const [unreadCount, setUnreadCount] = useState(0); // To track unread count

  // Fetch the current user's actual username from the backend using the email
  useEffect(() => {
    if (!email) {
      setError("Email ID is missing in localStorage.");
      setLoading(false);
      return;
    }

    // Fetch user info from backend (assuming the backend has an endpoint for user profile)
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://4sightoperations.in/users/profile/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        });
        const { username } = response.data; // Assuming the response contains a `username` field
        setCurrentUserName(username); // Store the actual username
      } catch (err) {
        setError("Failed to fetch user information.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [email]);

  // Fetch comments when segmentId changes
  useEffect(() => {
    if (!segmentId) {
      setError("Segment ID is missing.");
      setLoading(false);
      return;
    }
    fetchComments(); // Fetch comments if segmentId is available
    fetchUnreadCount(segmentId); // Fetch unread count when segmentId is available
  }, [segmentId]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (comment.trim() !== "") {
      try {
        setLoading(true);
        await axios.post(
          `https://4sightoperations.in/discussion/create/`,
          {
            segment: segmentId,
            userId: email, // Send email as user ID
            comment: comment,
          },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("Token")}`,
            },
          }
        );
        setComment(""); // Reset comment input
        fetchComments(); // Fetch the updated comments
        fetchUnreadCount(segmentId); // Update unread count after comment is posted
      } catch (err) {
        setError("Failed to post comment. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  // Fetch comments for the specific segment
// Fetch comments for the specific segment
const fetchComments = async () => {
    if (!segmentId) {
      setError("Segment ID is missing.");
      setLoading(false);
      return;
    }
    setLoading(true);
    setError(null);
  
    try {
      const response = await axios.get(
        `https://4sightoperations.in/discussion/list/?segment=${segmentId}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        }
      );
  
      // Replace userId with the corresponding username for each comment
      const commentsWithUsernames = await Promise.all(response.data.map(async (commentData) => {
        const usernameResponse = await axios.get(`https://4sightoperations.in/users/get_username_by_id/`, {
          params: { user_id: commentData.user }, // Fetch username by user ID
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        });
  
        // If the username fetch is successful, update the comment data
        if (usernameResponse.data.username) {
          commentData.user = usernameResponse.data.username; // Replace user ID with username
        }
        return commentData;
      }));
  
      setComments(commentsWithUsernames); // Update comments state with usernames
    } catch (err) {
      setError("Failed to fetch comments. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch unread count for the current user
  const fetchUnreadCount = async (segmentId) => {
    try {
      const response = await fetch(
        `https://4sightoperations.in/discussion/unread_count/?segment_id=${segmentId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unread count");
      }
      const data = await response.json();
      setUnreadCount(data.unreadCount || 0); // Set the unread count in state
    } catch (error) {
      console.error("Error fetching unread count:", error);
      setUnreadCount(0); // Default to 0 if an error occurs
    }
  };
  

  // Mark a comment as read
  const markCommentAsRead = async (commentId) => {
    try {
      // Fetch the segmentId and discussionId (commentId in this case) from the commentData
      const discussionId = commentId; // Assuming commentId corresponds to the discussion ID in your database
      
      await axios.post(
        `https://4sightoperations.in/discussion/mark_as_read/`,
        { segment_id: segmentId, discussion_id: discussionId },  // Pass segment_id and discussion_id
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        }
      );
      fetchUnreadCount(); // Re-fetch unread count after marking as read
    } catch (err) {
      setError("Failed to mark comment as read.");
    }
  };
  

  return (
    <div style={{ padding: "20px", display: "flex", flexDirection: "column", height: "100vh" }}>
      <h1>Project Discussion</h1>

      {/* Show loading spinner while fetching comments */}
      {loading && <Spinner animation="border" variant="primary" />}

      {/* Show error message if there was an issue */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Unread count */}
      <h3>Unread Comments: {unreadCount}</h3>

      {/* Discussion list */}
      <div style={{ flex: 1, overflowY: "auto", marginBottom: "20px" }}>
        <h3>Discussion:</h3>
        <ListGroup>
          {comments.length > 0 ? (
            comments.map((commentData, index) => (
              <ListGroup.Item
                key={index}
                style={{
                  display: "flex",
                  justifyContent:
                    commentData.user === currentUserName ? "flex-start" : "flex-end", // Align based on the actual username
                  textAlign: commentData.user === currentUserName ? "left" : "right", // Align text based on user
                  backgroundColor:
                    commentData.user === currentUserName ? "#f0f8ff" : "#e6f7ff", // Different background color for current user
                  borderRadius: "10px",
                  padding: "10px",
                  margin: "5px 0",
                  maxWidth: "80%", // Limit the width of each comment
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add subtle shadow effect
                }}
                onClick={() => markCommentAsRead(commentData.id)} // Mark as read on click
              >
                <strong>{commentData.user}</strong>: {commentData.comment}
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item>No comments yet. Be the first to comment!</ListGroup.Item>
          )}
        </ListGroup>
      </div>

      {/* Textbox for adding comments */}
      <Form onSubmit={handleCommentSubmit} style={{ marginBottom: "20px", position: "sticky", bottom: 0 }}>
        <Form.Group controlId="commentInput">
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Write your comment here..."
            value={comment}
            onChange={handleCommentChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? "Posting..." : "Post Comment"}
        </Button>
      </Form>
    </div>
  );
};

export default JTDiscussionPage;
