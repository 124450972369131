// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AuthContext, { AuthProvider } from './Components/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute';
import NavigationBar from './Components/NavigationBar';
import SideNavbar from './Components/SideNavbar';
import JTSide from './Components/JTSide';
import Operations from './Components/Operations';
import Awn1 from './Components/Awn1';
import Login from './Components/Login';
import SignUpForm from './Components/SignUpForm';
import Home from './Components/Home';
import DisplayData from './Components/DisplayData';
import FileUpload from './Components/ExcelUpload';
import CrossTab from './Components/CrossTab';
import Chatbot from './Components/Chatbot';
import Norms from './Components/Norms';
import Filters from './Components/Filters';
import SurveyDetails from './Components/SurveyDetails';
import JTProjectList from './Components/JTProjectList';

import Conclusion from './Components/Conclusion';
import SegView from './Components/SegView';
import JTNewEntry from './Components/JTNewEntry';
import Survey from './Components/Survey';
import DriverDataUpload from './Components/DriverDataUpload';
import DriverAnalysisForm from './Components/DriverAnalysisForm';
import NormsProjectSummaryCard from './Components/NormsProjectSummaryCard';
import JTSegmentSummary from './Components/JTSegmentSummary';
import './App.css';
import SurveyInsights from './Components/SurveyInsights';
import DriverUserManual from './Components/DriverUsermanual';
import DriverAnalysisGuide from './Components/DriverAnalysisGuide';
import Timesheet from './Components/Timesheet';
import JTDiscussionPage from './Components/JTDiscussionPage'
import UserProfile from './Components/UserProfile';
function App() {
  const [fileData, setFileData] = useState([]);

  return (
    <AuthProvider>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppContent fileData={fileData} setFileData={setFileData} />
        </LocalizationProvider>
      </Router>
    </AuthProvider>
  );
}

const AppContent = ({ fileData, setFileData }) => {
  const location = useLocation();

  const [columns, setColumns] = useState([]);
  const [binaryColumns, setBinaryColumns] = useState([]);
  const [fileId, setFileId] = useState(null);
  

  // Sidebar paths
  const sidebarPaths = [
    '/home',
    '/display-data',
    '/upload',
    '/cross-tab',
    '/chatbot',
    '/norms',
    '/filters',
     // Include the new path here
  ];

  const JTsidebarPaths = [
    '/projects_list',
    '/create-new',
    '/edit',
    '/project_detailed_view',
    '/project_summary',
    '/discussion'
  ];
  const SummaryCardWrapper = () => {
    const location = useLocation();
    const { summaryData } = location.state || {};

    if (!summaryData) {
        return <div>No summary data available.</div>;
    }

    return <NormsProjectSummaryCard summaryData={summaryData} />;
};
  // Determine which sidebar to show
  const showSidebar = sidebarPaths.some(path => location.pathname.startsWith(path));
  const showJTSidebar = JTsidebarPaths.some(path => location.pathname.startsWith(path));

  return (
    <>
      <NavigationBar />
      <Container fluid>
        <Row>
          <AuthContext.Consumer>
            {({ isLoggedIn }) =>
              isLoggedIn && (
                <>
                  {showSidebar && (
                    <Col sm={1} className="p-0">
                      <SideNavbar />
                    </Col>
                  )}
                  {showJTSidebar && (
                    <Col sm={1} className="p-0">
                      <JTSide />
                    </Col>
                  )}
                </>
              )
            }
          </AuthContext.Consumer>
          <Col sm={showSidebar || showJTSidebar ? 11 : 12} className="p-0">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Operations />} />
              <Route path="/awn" element={<Awn1 />} />
              <Route path="/login" element={<Login />} />

              <Route path="/signup" element={<SignUpForm />} />
              
              <Route path="/driver_analysis_user_manual" element={<DriverUserManual />} />
              <Route path="/driver_analysis_guide" element={<DriverAnalysisGuide />} />

              {/* Protected Routes */}
              <Route path="/update-profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
              <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
              <Route
                path="/display-data/:fileName"
                element={<ProtectedRoute><DisplayData fileData={fileData} /></ProtectedRoute>}
              />
              <Route
                path="/upload"
                element={<ProtectedRoute><FileUpload setFileData={setFileData} /></ProtectedRoute>}
              />
              <Route path="/cross-tab" element={<ProtectedRoute><CrossTab /></ProtectedRoute>} />
              <Route path="/chatbot" element={<ProtectedRoute><Chatbot /></ProtectedRoute>} />
              <Route path="/norms" element={<ProtectedRoute><Norms /></ProtectedRoute>} />
              <Route path="/filters" element={<ProtectedRoute><Filters /></ProtectedRoute>} />
              <Route path="/survey_details" element={<ProtectedRoute><SurveyDetails /></ProtectedRoute>} />
              <Route path="/survey_insights" element={<ProtectedRoute><SurveyInsights /></ProtectedRoute>} />
              <Route path="/summary" element={<ProtectedRoute><SummaryCardWrapper /></ProtectedRoute>} />
              <Route path="/projects_list" element={<ProtectedRoute><JTProjectList /></ProtectedRoute>} />
              <Route
                path="/satisfactionsurvey/:surveyId"
                element={<ProtectedRoute><Survey /></ProtectedRoute>}
              />
              <Route path="/create-new" element={<ProtectedRoute><JTNewEntry /></ProtectedRoute>} />
              <Route path="/edit" element={<ProtectedRoute><JTNewEntry /></ProtectedRoute>} />
              <Route path="/project_summary" element={<ProtectedRoute><JTSegmentSummary /></ProtectedRoute>} />
              <Route path="/discussion" element={<ProtectedRoute><JTDiscussionPage /></ProtectedRoute>} />

               <Route path="/conclusion" element={<ProtectedRoute><Conclusion /></ProtectedRoute>} />
              <Route path="/project_detailed_view" element={<ProtectedRoute><SegView /></ProtectedRoute>} />
              <Route path="/timesheet" element={<ProtectedRoute><Timesheet /></ProtectedRoute>} />

              {/* Driver Analysis Path */}
              <Route
                path="/driver_analysis"
                element={
                  <div >
                    <Col >
                    <Row>
                      <Col>
                      <ProtectedRoute> <DriverDataUpload
                      setColumns={setColumns}
                      setFileId={setFileId}
                      setBinaryColumns={setBinaryColumns}
                    /></ProtectedRoute>
                    </Col>
                    </Row>
                    <Row>
                      <Col>
                    {Array.isArray(columns) && columns.length > 0 && (
                      <ProtectedRoute>
                      <DriverAnalysisForm
                        columns={columns}
                        binaryColumns={binaryColumns} // Pass binaryColumns as-is
                        fileId={fileId}
                      /></ProtectedRoute>
                    )}
                    </Col>
                    </Row>
                    </Col>
                  </div>
                }
              />


              {/* Redirect any undefined paths to home */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default App;
