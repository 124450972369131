import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AWNimg from "./compliance.png";
import FSimg from "./checklist.png";
import JTimg from "./job.png";
import NNorms from "./norms.jpg";
import DDrivers from "./drivers.jpg";
import TSheet from "./timesheet.jpg";

function Start() {
  const navigate = useNavigate();

  const buttons = [
    { title: "Analysis Warning Note", img: AWNimg, action: () => navigate("/awn") },
    // { title: "Feedback Survey", img: FSimg, action: () => navigate("/survey_details") },
    { title: "Job Tracker", img: JTimg, action: () => navigate("/projects_list") },
    { title: "Norms", img: NNorms, action: () => navigate("/home") },
    { title: "Driver Analysis", img: DDrivers, action: () => navigate("/driver_analysis") },
    { title: "Timesheet", img: TSheet, action: () => navigate("/timesheet") },
  ];

  return (
    <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", padding: "60px" }}>
      <Typography
        varia   nt="h4"
        align="center"
        gutterBottom
        style={{ fontFamily: "Arial, sans-serif", marginBottom: "30px" }}
      >
       
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {buttons.map((btn, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              onClick={btn.action}
              style={{
                cursor: "pointer",
                transition: "transform 0.3s",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "15px",
              }}
              onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <CardContent
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                <img
                  src={btn.img}
                  alt={btn.title}
                  style={{
                    width: "60px",
                    height: "60px",
                    marginRight: "10px",
                    borderRadius: "10px",
                  }}
                />
                <Typography variant="h6" style={{ fontFamily: "Arial, sans-serif" }}>
                  {btn.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Start;
