// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const loggedInState = localStorage.getItem('isLoggedIn');
    const storedUsername = localStorage.getItem('username');
    const storedToken = localStorage.getItem('token');

    //console.log("Initial load - localStorage values:", { loggedInState, storedUsername, storedToken });
    
    if (loggedInState === 'true' && storedToken) {
      setIsLoggedIn(true);
      setUsername(storedUsername || '');
      setToken(storedToken);
    } else {
      setIsLoggedIn(false);
      setUsername('');
      setToken(null);
    }
    
    setLoading(false); // Set loading to false after initialization
  }, []);

  const handleLogin = (user, authToken) => {
    //console.log("Logging in - setting localStorage and state");
    setIsLoggedIn(true);
    setUsername(user);
    setToken(authToken);
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('username', user);
    localStorage.setItem('token', authToken);
    localStorage.setItem('Token', authToken);
  };

  const handleLogout = () => {
    //alert("hi")
   // console.log("Logging out - clearing localStorage and state");
    setIsLoggedIn(false);
    setUsername('');
    setToken(null);
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('Token');
  };

  //sconsole.log("AuthContext state:", { isLoggedIn, username, token, loading });

  return (
    <AuthContext.Provider value={{ isLoggedIn, username, token, loading, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
