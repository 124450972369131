// JTFormFields.js
import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const JTFormFields = React.memo(({
  formData,
  setFormData,
  handleSegmentNumberChange,
  months,
  errors,
  isEditMode, // Receive isEditMode as a prop
}) => {
  return (
    <Row className="gy-3">
      {Object.keys(formData).map((field, index) => {
        // Exclude 'editMode' field
        if (field === "editMode") {
          return null;
        }

        if (field === "segment_number") {
          return (
            <Col xs={12} md={4} key={index}>
              <Form.Group controlId={field}>
                <Form.Label>{field.replace(/_/g, " ").toUpperCase()}</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  value={formData[field]}
                  onChange={(e) => handleSegmentNumberChange(e.target.value)}
                />
                {errors.segment_number && (
                  <Form.Text className="text-danger">
                    {errors.segment_number}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          );
        } else if (field === "month") {
          return (
            <Col xs={12} md={4} key={index}>
              <Form.Group controlId={field}>
                <Form.Label>{field.replace(/_/g, " ").toUpperCase()}</Form.Label>
                <Form.Control
                  as="select"
                  value={formData[field]}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [field]: e.target.value,
                    }))
                  }
                >
                  <option value="">Select Month</option>
                  {months.map((month, idx) => (
                    <option key={idx} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
                {errors.month && (
                  <Form.Text className="text-danger">{errors.month}</Form.Text>
                )}
              </Form.Group>
            </Col>
          );
        } else if (field === "year") {
          return (
            <Col xs={12} md={4} key={index}>
              <Form.Group controlId={field}>
                <Form.Label>{field.replace(/_/g, " ").toUpperCase()}</Form.Label>
                <Form.Control
                  type="number"
                  min="1000"
                  max="9999"
                  value={formData[field]}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [field]: e.target.value,
                    }))
                  }
                  placeholder="Enter 4-digit year"
                />
                {errors.year && (
                  <Form.Text className="text-danger">{errors.year}</Form.Text>
                )}
              </Form.Group>
            </Col>
          );
        } else if (field === "job_number" && isEditMode) {
          // Disable 'job_number' field in edit mode if it's a unique identifier
          return (
            <Col xs={12} md={4} key={index}>
              <Form.Group controlId={field}>
                <Form.Label>{field.replace(/_/g, " ").toUpperCase()}</Form.Label>
                <Form.Control
                  type="text"
                  value={formData[field]}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [field]: e.target.value,
                    }))
                  }
                  disabled={isEditMode} // Disable in edit mode
                />
                {errors[field] && (
                  <Form.Text className="text-danger">{errors[field]}</Form.Text>
                )}
              </Form.Group>
            </Col>
          );
        } else {
          return (
            <Col xs={12} md={4} key={index}>
              <Form.Group controlId={field}>
                <Form.Label>{field.replace(/_/g, " ").toUpperCase()}</Form.Label>
                <Form.Control
                  type="text"
                  value={formData[field]}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      [field]: e.target.value,
                    }))
                  }
                />
                {errors[field] && (
                  <Form.Text className="text-danger">{errors[field]}</Form.Text>
                )}
              </Form.Group>
            </Col>
          );
        }
      })}
    </Row>
  );
});

export default JTFormFields;
