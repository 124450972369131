import React, { useState } from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./JTSegmentDetails.css"; // Custom CSS for additional styling
import { useNavigate } from "react-router-dom";
import  { forwardRef, useImperativeHandle } from "react";
import axios from "axios";
const JTSegmentDetails = forwardRef(({ formData, handleChange, errors = {}, segmentIndex = 0 }, ref) => {
  const navigate = useNavigate();
  const [error, setError] = useState(""); // For general error messages
  const [successMessage, setSuccessMessage] = useState(""); // For success messages
  const [showSuccessToast, setShowSuccessToast] = useState(false); // Controls success toast visibility
  //console.log("FFFFFFFFFFFF", formData);

  // Dropdown options (alphabetically ordered)
  const contactPersonsScripting = ['Anuradha', 'Nagajyothi', 'Raghuram', 'Saravanakumar', 'Srivendhan', 'Varun'];
  const contactPersonsDP = ['Anuradha', 'Nagajyothi', 'Raghuram', 'Saravanakumar', 'Srivendhan', 'Varun'];
  const contactPersonsResearch = ['Ananya', 'Ashish', 'Aruna', 'Afaq', 'Caroline Maina', 'Elias Korban', 'Krati', 'Kunal', 'Lina Almani', 'Mahima Choudhary','Manoj Kumar', 'Mohamed Ismail', 'Muhammed Raafat', 'Nour Sheikh Sulaiman', 'Priya', 'Pulkit', 'Rita KFoury', 'Saranya', 'Sonal', 'Tanuj Guptha', 'Vinivaa'];

  const projectStatus = ["Open", "Closed", "Hold"];
  const analysisGroupHead = ['Ramamoorthy', 'Sathish Kumar'];

  const advancedAnalyticsOptions = [
    'Chi-Square Analysis', 'Correlation Analysis', 'Driver Analysis',
    'Jaccard Analysis', 'Max-diff analysis', 'Others'
  ];
  const surveyPlatformOptions = ['Forsta', 'SurveyToGo', 'XEBO', 'Others'];
  const projectClassification = ['Class A', 'Class B', 'Class C', 'Class D'];
  const dpPlatformOptions = ['IBM Dimensions', 'IBM SPSS', 'Q Research Platform', 'Quantum', 'Others'];

  // Constant fields grouped by category
  const researchFields = [
    "segment_name",
    "actual_sample_size",
    "achieved_sample_size",
    "final_processed_samples",
    "research_group_head",
    "contact_person_in_research",
    "analysis_group_head",
    "contact_person_for_scripting",
    "contact_person_for_dp",
    "status"
  ];

  const dateFields = [
    "date_of_awn",

    "qnr_received_date",
    "ap_received_date",
    "latest_topline_date",
    "expected_date_of_link",
    "actual_date_of_link",
    "fw_start_date",
    "fw_end_date",

    "expected_date_of_final_output",
    "actual_date_of_final_output",
  ];

  const yesNoFields = [
    "data_correction_done",
    "check_list_followed",
    "feedback_survey_done",
    "norms_done",
    "archive_taken",
    "oe_coding",
    "backup_taken",
  ];

  const additionalFields = [
    "completed_month",
    "backup_folderpath",
    "oe_costing",
    "no_of_top_lines",
    "advanced_analytics",
    "survey_platform",
    "dp_platform",
    "project_classification",
    "remarks",
  ];

  const numericFields = [
    "actual_sample_size",
    "achieved_sample_size",
    "final_processed_samples",
    "loi",
    "oe_costing",
    "number_of_response",
  ];
  useImperativeHandle(ref, () => ({
    formData,
    handleStatusChange: async (value, feedbackDone) => {
      handleChange('status', value);
  
      if (value === 'Closed' && feedbackDone !== 'Yes') {
        const {
          segment_name,
          actual_date_of_final_output,
          completed_month,
          contact_person_in_research = 'Not Available',
          contact_person_for_scripting = 'Not Applicable',
          contact_person_for_dp = 'Not Applicable',
          job_number,
          project_name
        } = formData;
  
        try {
          // Fetch segment ID using both job_number and segment_name
          const response = await axios.get(
            `https://4sightoperations.in/api/segments/fetch_segment_id_by_job_and_name/`, {
              params: {
                job_number: job_number,
                segment_name: segment_name,
              },
              headers: {
                Authorization: `Token ${localStorage.getItem("Token")}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          // Debug: Log full response data
          console.log("Full Response Data:", response.data);
  
          // Ensure the segmentId is available in the response data
          const segmentId = response.data.segmentId;
  
          if (!segmentId) {
            console.error("Segment ID is missing from the response.");
            alert("Failed to retrieve segment ID.");
            return;
          }
  
          // Prepare static data to be passed to survey details page
          const staticData = {
            researcherInCharge: contact_person_in_research,
            projectName: `${project_name} ${segment_name}`,
            jobNumber: job_number,
            segmentId: segmentId,  // Pass the fetched segmentId
            year: new Date(actual_date_of_final_output).getFullYear().toString(),
            month: completed_month,
            scriptingInCharge: contact_person_for_scripting,
            dpInCharge: contact_person_for_dp,
          };
  
          console.log("Static Data with Segment ID:", staticData);
  
          // Navigate to the survey details page with the static data
          navigate("/survey_details", { state: { data: staticData } });
        } catch (error) {
          console.error("Error fetching segment ID:", error.message);
          alert("Failed to fetch segment ID");
        }
      }
    }
  }), [formData, handleChange, navigate]);
  
  const handleNumericChange = (field, value) => {
    const numericValue = value === "" ? null : value.replace(/[^0-9.]/g, ""); // Allow only numbers and decimals
    handleChange(field, numericValue ? Number(numericValue) : null);
  };

  const renderFields = (fields) =>
    fields.map((field, index) => {
      const isSegmentName = field === "segment_name";
      const isError = errors[`${field}_${segmentIndex}`];
      return(
      
      <Col xs={12} md={6} className="mb-3" key={index}>
        <div className="field-container">
          <Form.Label className="highlighted-label mb-1">
            {field.replace(/_/g, " ").toUpperCase()}
          </Form.Label>
          {field.toLowerCase().includes("date") ? (
            <DatePicker
              selected={formData[field] ? new Date(formData[field]) : null}
              onChange={(date) => {
                if (date) {
                  // Adjust the date to local timezone
                  const localDate = new Date(
                    date.getTime() - date.getTimezoneOffset() * 60000
                  ).toISOString().split("T")[0];
                  handleChange(field, localDate);
                } else {
                  handleChange(field, null);
                }
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select a date"
              className={`form-control custom-datepicker ${
                errors[`${field}_${segmentIndex}`] ? "is-invalid" : ""
              }`}
            />
          ) : yesNoFields.includes(field) ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Yes/No</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="NA">Not Applicable</option>
            </Form.Select>
          ) : field === "status" ? (
            <Form.Select
            value={formData[field] || ""}
            onChange={(e) => handleChange(field, e.target.value)} // Update segment data only
            isInvalid={!!errors[`${field}_${segmentIndex}`]}
          >
            <option value="">Select Project Status</option>
            {projectStatus.map((status, idx) => (
              <option key={idx} value={status}>
                {status}
              </option>
            ))}
          </Form.Select>

) : field === "segment_name" ? (
  <Form.Control
    type="text"
    value={formData[field] || ""}
    onChange={(e) => handleChange(field, e.target.value)}
    isInvalid={isError}
    required
  />
)  : field === "contact_person_for_scripting" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Contact Person</option>
              {contactPersonsScripting.map((person, idx) => (
                <option key={idx} value={person}>
                  {person}
                </option>
              ))}
            </Form.Select>
          ) : field === "contact_person_for_dp" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Contact Person</option>
              {contactPersonsDP.map((person, idx) => (
                <option key={idx} value={person}>
                  {person}
                </option>
              ))}
            </Form.Select>
          ) : field === "research_group_head" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Research Head</option>
              {contactPersonsResearch.map((person, idx) => (
                <option key={idx} value={person}>
                  {person}
                </option>
              ))}
            </Form.Select>
          ) : field === "contact_person_in_research" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Contact Person</option>
              {contactPersonsResearch.map((person, idx) => (
                <option key={idx} value={person}>
                  {person}
                </option>
              ))}
            </Form.Select>
          ) : field === "analysis_group_head" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Analysis Group Head</option>
              {analysisGroupHead.map((head, idx) => (
                <option key={idx} value={head}>
                  {head}
                </option>
              ))}
            </Form.Select>
          ) : field === "advanced_analytics" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Advanced Analytics</option>
              {advancedAnalyticsOptions.map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          ) : field === "survey_platform" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Survey Platform</option>
              {surveyPlatformOptions.map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          ) : field === "project_classification" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select Classification</option>
              {projectClassification.map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          ) : field === "dp_platform" ? (
            <Form.Select
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            >
              <option value="">Select DP Platform</option>
              {dpPlatformOptions.map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          ) : numericFields.includes(field) ? (
            <Form.Control
              type="text"
              value={formData[field] || ""}
              onChange={(e) => handleNumericChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            />
          ) : (
            <Form.Control
              type="text"
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
              isInvalid={!!errors[`${field}_${segmentIndex}`]}
            />
          )}
          <Form.Control.Feedback type="invalid">
            {errors[`${field}_${segmentIndex}`]}
          </Form.Control.Feedback>
        </div>
      </Col>
    )});

  return (
    <div className="segment-details-container">
      <Form>
        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-primary text-white">
            <h5 className="mb-0">Reasearch and Analysis</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-3">{renderFields(researchFields)}</Row>
          </Card.Body>
        </Card>

        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-secondary text-white">
            <h5 className="mb-0">Date Information</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-4 gx-4">{renderFields(dateFields)}</Row>
          </Card.Body>
        </Card>

        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-success text-white">
            <h5 className="mb-0">Checks</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-3">{renderFields(yesNoFields)}</Row>
          </Card.Body>
        </Card>

        <Card className="mb-4 shadow-sm">
          <Card.Header className="bg-dark text-white">
            <h5 className="mb-0">Additional Information</h5>
          </Card.Header>
          <Card.Body>
            <Row className="gy-3">{renderFields(additionalFields)}</Row>
          </Card.Body>
        </Card>
      </Form>
    </div>
  );
});

export default JTSegmentDetails;
