import React, { useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { Form, Button, Table, Alert, Container, Row, Col } from 'react-bootstrap'; // Import React-Bootstrap components
import ExcelJS from 'exceljs';

const DriverAnalysisForm = ({ columns = [], binaryColumns = [], fileId }) => {
    const [sample, setSample] = useState(null); // Add this state

    const [dependent, setDependent] = useState('');
    const [independent, setIndependent] = useState([]);
    const [nanAction, setNanAction] = useState('');
    const [analysisType, setAnalysisType] = useState('linear_regression');
    const [filter, setFilter] = useState(''); // New state for Filters
    const [results, setResults] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async () => {
        setResults([]); // Clear previous results
        setErrorMessage('');
        setSuccessMessage('Running analysis...');
    
        // if (!dependent || independent.length === 0 || !nanAction || !analysisType) {
        //     setErrorMessage("Please fill all fields before running the analysis.");
        //     return;
        // }
    
        if (!fileId) {
            setErrorMessage("File ID is missing. Please upload the data file first.");
            return;
        }
    
        try {
            const payload = {
                file_id: fileId,
                dependent,
                independent,
                nan_action: nanAction || null,
                analysis_type: analysisType,
                binary_data: filter || null,
            };
    
            const token = localStorage.getItem('Token');
            if (!token) {
                setErrorMessage("Authentication token is missing. Please log in again.");
                return;
            }
    
            const response = await axios.post(
                "https://4sightoperations.in/drivers/analyze/",
                payload,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            if (response.data.error) {
                setErrorMessage(response.data.error);
                setSuccessMessage('');
                setResults([]);
            } else {
                setResults(response.data.data);
                setSample(response.data.sample);
                setSuccessMessage(response.data.message || "Analysis completed successfully.");
                setErrorMessage('');
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.error || "Failed to perform analysis.");
            setSuccessMessage('');
        }
    };
    const handleClearForm = () => {
        setDependent('');
        setIndependent([]);
        setNanAction('');
        setFilter('');
        setResults([]);
        setErrorMessage('');
        setSuccessMessage('');
    };
    
    
    const handleExportToExcel = async () => {
        if (results.length === 0) {
            setErrorMessage("No results to export.");
            return;
        }
    
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Driver Analysis');
    
            if (analysisType === "linear_regression") {
                worksheet.columns = [
                    { header: 'Driver', key: 'Driver', width: 40 },
                    { header: 'Coefficient', key: 'Coefficient', width: 15 },
                    { header: 'Absolute Coefficient', key: 'Absolute_Coefficient', width: 20 },
                    { header: 'Coefficient (%)', key: 'Coefficient_Percent', width: 15 },
                    { header: 'R-Squared', key: 'R_Squared', width: 15 },
                ];
            } else {
                worksheet.columns = [
                    { header: 'Driver', key: 'Driver', width: 40 },
                    { header: 'Kruskal Importance (%)', key: 'Kruskal_Importance', width: 20 },
                    { header: 'Lower Confidence Bound (0.025)', key: 'Lower_Confidence_Bound', width: 25 },
                    { header: 'Raw Kruskal Importance', key: 'Raw_Kruskal_Importance', width: 25 },
                    { header: 'Upper Confidence Bound (0.975)', key: 'Upper_Confidence_Bound', width: 25 },
                ];
            }
    
            results.forEach((row) => {
                worksheet.addRow({
                    Driver: row.Driver || '-',
                    Coefficient: row.Coefficient?.toFixed(2) || '-',
                    Absolute_Coefficient: row['Absolute Coefficient']?.toFixed(2) || '-',
                    Coefficient_Percent: row['Coefficient (%)']?.toFixed(2) || '-',
                    R_Squared: row['R-Squared']?.toFixed(4) || '-',
                    Kruskal_Importance: row['Kruskal Importance (%)']?.toFixed(2) || '-',
                    Lower_Confidence_Bound: row['Lower Confidence Bound (0.025)']?.toFixed(4) || '-',
                    Raw_Kruskal_Importance: row['Raw Kruskal Importance']?.toFixed(4) || '-',
                    Upper_Confidence_Bound: row['Upper Confidence Bound (0.975)']?.toFixed(4) || '-',
                });
            });
    
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Driver_Analysis_Results.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            setSuccessMessage("Results exported successfully.");
        } catch (error) {
            setErrorMessage(error.response?.data?.error || "An unexpected error occurred. Please try again.");

        }
    };
    

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={12}>
                    <h3 className="text-center mt-4 mb-4">Driver Analysis</h3>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    <Button variant="secondary" className="mt-3 ml-2" onClick={handleClearForm}>
    Reset Form
</Button>
                    <Form>
                    <Form.Group controlId="analysisType">
                        <Form.Label className="custom-label">Analysis Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={analysisType}
                            onChange={(e) => setAnalysisType(e.target.value)}
                        >
                            <option value="linear_regression">Linear Regression</option>
                            <option value="kruskal">Kruskal Analysis</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="dependentVariable">
    <Form.Label className="custom-label">Dependent Variable</Form.Label>
    <Form.Control
        as="select"
        value={dependent}
        onChange={(e) => setDependent(e.target.value)}
    >
        <option value="">Select Dependent Variable</option>
        {columns.map((col) => (
            <option key={col.name} value={col.name}>
                {col.label}
            </option>
        ))}
    </Form.Control>
</Form.Group>

<Form.Group controlId="independentVariables">
    <Form.Label className="custom-label">Independent Variables</Form.Label>
    <Form.Control
        as="select"
        multiple
        value={independent}
        onChange={(e) =>
            setIndependent([...e.target.selectedOptions].map((o) => o.value))
        }
        style={{ height: '200px' }}
    >
        {columns.map((col) => (
            <option key={col.name} value={col.name}>
                {col.label}
            </option>
        ))}
    </Form.Control>
</Form.Group>


                    <Form.Group controlId="filters">
    <Form.Label className="custom-label">Filters (Binary Fields)</Form.Label>
    <Form.Control
        as="select"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        disabled={binaryColumns.length === 0}
    >
        <option value="">Select Filter</option>
        {binaryColumns.map((col) => (
            <option key={col.name} value={col.name}>
                {col.label}
            </option>
        ))}
    </Form.Control>
    {binaryColumns.length === 0 && (
                            <small className="text-muted">No binary columns available.</small>
                        )}
</Form.Group>


                        <Form.Group controlId="nanHandling">
                            <Form.Label className="custom-label">NaN Handling</Form.Label>
                            <Form.Control
                                as="select"
                                value={nanAction}
                                onChange={(e) => setNanAction(e.target.value)}
                            >
                                <option value="">Select Option</option>
                                <option value="Drop NA">Drop Missing Cases(Default)</option>
                                <option value="Replace with Mean">Replace with Mean</option>
                                <option value="Replace with Median">Replace with Median</option>
                                
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" className="mt-3" onClick={handleSubmit}>
                            Run Analysis
                        </Button>
                    </Form>

                    {results.length > 0 && (
    <div className="mt-4">
        <Button variant="success" className="mb-3" onClick={handleExportToExcel}>
            Export to Excel
        </Button>
        <Table striped bordered hover>
            <thead>
                <tr >
                    <td className="custom-label">Samples considered</td>
                    <td><td className="custom-label">{sample || '-'}</td></td>
                    </tr>
                <tr>

                    <th>Driver</th>
                    {analysisType === "linear_regression" ? (
                        <>
                            <th>Coefficient</th>
                            <th>Absolute Coefficient</th>
                            <th>Coefficient (%)</th>
                            <th>R-Squared</th>
                        </>
                    ) : (
                        <>
                            <th>Kruskal Importance (%)</th>
                            <th>Lower Confidence Bound (0.025)</th>
                            <th>Raw Kruskal Importance</th>
                            <th>Upper Confidence Bound (0.975)</th>
                        </>
                    )}
                </tr>
            </thead>
            <tbody>
                {results.map((row, index) => (
                    <tr key={index}>
                        <td>{row.Driver || '-'}</td>
                        {analysisType === "linear_regression" ? (
                            <>
                                <td>{row.Coefficient?.toFixed(2) || '-'}</td>
                                <td>{row['Absolute Coefficient']?.toFixed(2) || '-'}</td>
                                <td>{row['Coefficient (%)']?.toFixed(2) || '-'}</td>
                                <td>{row['R-Squared'] ? (row['R-Squared'] * 100).toFixed(2) + '%' : '-'}</td>

                            </>
                        ) : (
                            <>
                                <td>{row['Kruskal Importance (%)']?.toFixed(2) || '-'}</td>
                                <td>{row['Lower Confidence Bound (0.025)']?.toFixed(4) || '-'}</td>
                                <td>{row['Raw Kruskal Importance']?.toFixed(4) || '-'}</td>
                                <td>{row['Upper Confidence Bound (0.975)']?.toFixed(4) || '-'}</td>
                            </>
                        )}
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
)}

                </Col>
            </Row>
        </Container>
    );
};

export default DriverAnalysisForm;
