import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab, Spinner, Button,Alert, Card, Table, Form,ListGroup ,Accordion } from "react-bootstrap";
import axios from "axios";
import JTMonthWiseReport from "./JTMonthWiseReport";
import JTStartMonthSummary from "./JTStartMonthSummary";
import './JTSegmentSummary.css';
import CummulativeSummary from "./CummulativeSummary"; 
const JTSegmentSummary = () => {
  const [completedMonths, setCompletedMonths] = useState([]);

  const [contactPersonsDP, setContactPersonsDP] = useState([]);
  const [contactPersonsScripting, setContactPersonsScripting] = useState([]);
  const [selectedDP, setSelectedDP] = useState("");
  const [selectedScripting, setSelectedScripting] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summaryDataDP, setSummaryDataDP] = useState(null);
  const [summaryDataScripting, setSummaryDataScripting] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");

  // Fetch data for dropdowns
  useEffect(() => {
    const fetchContactPersons = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("Token");
    
        const [dpResponse, scriptingResponse, monthResponse] = await Promise.all([
          axios.get("https://4sightoperations.in/api/segments/fetch_dp_contacts/", {
            headers: { Authorization: `Token ${token}` },
            params: { user: localStorage.getItem('username') }, // Pass as query parameter
        }),        
          axios.get("https://4sightoperations.in/api/segments/fetch_scripting_contacts/", {
            headers: { Authorization: `Token ${token}` },
            params: { user: localStorage.getItem('username') }, // Pass as query parameter
          }),
          axios.get("https://4sightoperations.in/api/segments/project_summary/", {
            headers: { Authorization: `Token ${token}` },
          }),
        ]);
    
        setContactPersonsDP(dpResponse.data || []);
        
        setContactPersonsScripting(scriptingResponse.data || []);
        const uniqueMonths = [
          ...new Set(monthResponse.data.map((item) => item.completed_month)),
        ].filter(Boolean);
        setCompletedMonths(uniqueMonths.sort());
      } catch (err) {
        setError("Failed to fetch dropdown data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    

    fetchContactPersons();
  }, []);

  // Fetch summary data
  const fetchSummaryData = async (name, type) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("Token");
      const response = await axios.get("https://4sightoperations.in/api/segments/fetch_summary/", {
        params: { name, type_of_work: type },
        headers: { Authorization: `Token ${token}` },
      });

      if (type === "dp") {
        setSummaryDataDP(response.data);
      } else if (type === "scripting") {
        setSummaryDataScripting(response.data);
      }
    } catch (err) {
      setError("Failed to fetch summary data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Render binary attributes
  const renderBinaryAttributes = (summaryData) => {
    if (!summaryData?.binary_summary) return null;

    return (
      <Card className="mb-4">
        <Card.Header>Binary Checks</Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Yes</th>
                <th>No</th>
                <th>Blanks</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(summaryData.binary_summary).map(([field, counts]) => (
                <tr key={field}>
                  <td>{field.replace(/_/g, " ").toUpperCase()}</td>
                  <td>{counts.Yes || 0}</td>
                  <td>{counts.No || 0}</td>
                  <td>{counts.Null || 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  // Render categorical counts
  const renderCategoricalCounts = (summaryData) => {
    if (!summaryData?.categorical_counts) return null;
  
    return (
      <Card className="mb-4">
        <Card.Header>Categorical Counts</Card.Header>
        <Card.Body>
          <Accordion>
            {Object.entries(summaryData.categorical_counts).map(([key, value], index) => (
              <Accordion.Item eventKey={index.toString()} key={key}>
                <Accordion.Header>{key.replace(/_/g, " ").toUpperCase()}</Accordion.Header>
                <Accordion.Body>
                  {typeof value === "object" ? (
                    <ListGroup>
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <ListGroup.Item
                          key={subKey}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>{subKey.replace(/_/g, " ")}</span>
                          <span className="badge bg-primary rounded-pill">{subValue}</span>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <p>{value || "N/A"}</p>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Card.Body>
      </Card>
    );
  };

  // Render numeric aggregations
  const renderNumericAggregations = (summaryData) => {
    if (!summaryData?.numeric_aggregations) return null;
  
    return (
      <Card className="mb-4 shadow-lg border-0">
        <Card.Header className="bg-primary text-white">
          <h5 className="mb-0">Numeric Aggregations</h5>
        </Card.Header>
        <Card.Body className="bg-light">
          <Table striped bordered hover responsive className="text-center align-middle">
            <thead className="table-primary">
              <tr>
                <th>Metric</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(summaryData.numeric_aggregations).map(([key, value]) => (
                <tr key={key}>
                  <td className="fw-bold">{key.replace(/_/g, " ").toUpperCase()}</td>
                  <td>{value || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };
  

  return (
    <Container className="mt-5">
  <Tabs
    activeKey={activeTab}
    onSelect={(tabKey) => setActiveTab(tabKey)}
    id="segment-summary-tabs"
    className="custom-tabs mb-4"
  >
   <Tab eventKey="tab1" title="Report by DP">
  <div className="tab-content-container">
    <h3 className="text-center">Summary by Contact Person for DP</h3>
    {loading && (
      <div className="text-center">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    )}
    {error && <Alert variant="danger">{error}</Alert>}
    {!loading && (
      <>
        <div className="mb-4">
          <h5>Select Contact Person for DP</h5>
          <div className="d-flex flex-wrap justify-content-start">
            {contactPersonsDP.map((person, index) => (
              <Card
                key={index}
                className={`m-2 p-3 text-center ${
                  selectedDP === person ? "border-primary" : ""
                }`}
                style={{
                  cursor: "pointer",
                  width: "150px",
                  border: "2px solid",
                  borderColor: selectedDP === person ? "#0d6efd" : "#ccc",
                }}
                onClick={() => {
                  setSelectedDP(person);
                  fetchSummaryData(person, "dp");
                }}
              >
                <Card.Body>
                  <Card.Text>{person}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
        {selectedDP && (
          <>
            <h6 className="text-center">Data for: {selectedDP}</h6>
            {renderNumericAggregations(summaryDataDP)}
            {renderCategoricalCounts(summaryDataDP)}
            
            {renderBinaryAttributes(summaryDataDP)}
          </>
        )}
      </>
    )}
  </div>
</Tab>


    <Tab eventKey="tab2" title="Report by Scripting">
      <div className="tab-content-container">
        <h3 className="text-center">Summary by Contact Person for Scripting</h3>
        {loading && (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Loading...</p>
          </div>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {!loading && (
          <>
            <Form.Group controlId="scriptingSelect" className="mb-4">
              <Form.Label>Select Contact Person for Scripting</Form.Label>
              <Form.Control
                as="select"
                value={selectedScripting}
                onChange={(e) => {
                  setSelectedScripting(e.target.value);
                  fetchSummaryData(e.target.value, "scripting");
                }}
              >
                <option value="">-- Select Contact Person --</option>
                {contactPersonsScripting.map((person, index) => (
                  <option key={index} value={person}>
                    {person}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {renderNumericAggregations(summaryDataScripting)}
            {renderCategoricalCounts(summaryDataScripting)}
           
            {renderBinaryAttributes(summaryDataScripting)}
           
          </>
        )}
      </div>
    </Tab>
    <Tab eventKey="tab4" title="Report by Start Month">
      <div className="tab-content-container">
        <JTStartMonthSummary
          completedMonths={completedMonths}
          fetchSummaryData={fetchSummaryData}
          loading={loading}
          error={error}
        />
      </div>
    </Tab>
    <Tab eventKey="tab3" title="Report by Completed month">
      <div className="tab-content-container">
        <JTMonthWiseReport
          completedMonths={completedMonths}
          fetchSummaryData={fetchSummaryData}
          loading={loading}
          error={error}
        />
      </div>
    </Tab>

    <Tab eventKey="tab5" title="Cumulative Summary">
        <div className="tab-content-container">
          <CummulativeSummary />
        </div>
      </Tab>
    
  </Tabs>
</Container>

  );
};

export default JTSegmentSummary;
