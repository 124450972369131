import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserProfile.css';  // Assuming the CSS file is named 'UserProfile.css'

const UserProfile = ({ userId }) => {
  const [profile, setProfile] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    department: '',
    reporting_to: '',
    designation: '',
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://4sightoperations.in/users/user-list/');
        setUsers(response.data);
        
        // Get current user email from storage
        const currentUserEmail = localStorage.getItem('username');
        
        // Find the user with the matching email in the users list
        const currentUser = response.data.find(user => user.email === currentUserEmail);
        
        // If a match is found, set the selectedUserId
        if (currentUser) {
          setSelectedUserId(currentUser.id);
        }
      } catch (err) {
        setError('Failed to fetch users');
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://4sightoperations.in/users/profile/update/${selectedUserId}/`);
        setProfile(response.data);
        console.log(response.data);  // Check what data is returned from the API
        setFormData({
          name: response.data.name || '',
          department: response.data.department || '',
          reporting_to: response.data.reporting_to?.map((rpt) => rpt.id) || [],
          designation: response.data.designation || '',
        });
        
      } catch (err) {
        setError(err.response?.data?.detail || 'An error occurred');
      }
    };
  
    if (selectedUserId) {
      fetchUserProfile();
    }
  }, [selectedUserId]);
  
  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
    setProfile(null);
    setError(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'reporting_to' ? [parseInt(value)] : value,
    }));
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form data before submit:", formData); // Check what data is being sent
  
    try {
      const response = await axios.put(
        `https://4sightoperations.in/users/profile/update/${selectedUserId}/`,
        formData
      );
      console.log("Response after save:", response.data); // Log the updated profile data
      setProfile(response.data); // Update profile with the returned data
      setIsEditing(false);
    } catch (err) {
      console.log("Error on save:", err); // Check for any errors
      setError(err.response?.data?.detail || 'An error occurred');
    }
  };
  
  return (
    <div className="container">
      <h1>User Profile</h1>

      <label htmlFor="user-select">Select User:</label>
      <select
        id="user-select"
        value={selectedUserId}
        onChange={handleUserChange}
        
        
      >
          <option value="">-- Select a user --</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.username} ({user.email})
            </option>
          ))}
        </select>

      {!profile && <div>Loading...</div>}

      {profile && !isEditing && (
  <div className="profile-info">
    <p><strong>Name:</strong> {profile.name || 'Not available'}</p>
    <p><strong>Email:</strong> {profile.user?.email || 'Not available'}</p>
    <p><strong>Department:</strong> {profile.department || 'Not available'}</p>
    <p><strong>Designation:</strong> {profile.designation || 'Not available'}</p>
    {profile.reporting_to && profile.reporting_to.length > 0 && (
  <p><strong>Reporting To:</strong> 
    {profile.reporting_to.map((rpt, index) => (
      <span key={rpt.id}>
        {rpt.name}
        {index < profile.reporting_to.length - 1 && ', '}
      </span>
    ))}
  </p>
)}


    <div className="button-container">
      <button onClick={() => setIsEditing(true)}>Edit</button>
    </div>
  </div>
)}

      {profile && isEditing && (
        <form onSubmit={handleSubmit}>
          <div className="select-container">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="select-container">
            <label htmlFor="department">Department:</label>
            <select
              id="department"
              name="department"
              value={formData.department}
              onChange={handleInputChange}
            >
              <option value="">-- Select a department --</option>
              <option value="Data Processing">Data Processing</option>
              <option value="Research">Research</option>
              <option value="Operations">Operations</option>
            </select>
          </div>
          <div className="select-container">
            <label htmlFor="designation">Designation:</label>
            <select
              id="designation"
              name="designation"
              value={formData.designation}
              onChange={handleInputChange}
            >
              <option value="">-- Select a designation --</option>
              <option value="Junior Analyst">Junior Analyst</option>
              <option value="Senior Analyst">Senior Analyst</option>
              <option value="Data Head">Data Head</option>
              <option value="Manager">Manager</option>
              <option value="Associate Manager">Associate Manager</option>
            </select>
          </div>
          <div className="select-container">
            <label htmlFor="reporting_to">Reporting To:</label>
            <select
              id="reporting_to"
              name="reporting_to"
              value={formData.reporting_to}
              onChange={handleInputChange}
            >
              <option value="">-- Select a reporting user --</option>
              {users
                .filter((user) => user.id !== selectedUserId)
                .map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.username} ({user.email})
                  </option>
                ))}
            </select>
          </div>
          <div className="button-container">
            <button type="submit">Save</button>
          </div>
        </form>
      )}

      {error && <div className="error">Error: {error}</div>}
    </div>
  );
};

export default UserProfile;
