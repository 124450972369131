import { useEffect } from 'react';

function loadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.id = 'bootstrap-css';
  document.head.appendChild(link);
}

function unloadCSS() {
  const link = document.getElementById('bootstrap-css');
  if (link) {
    document.head.removeChild(link);
  }
}

export function useBootstrap() {
  useEffect(() => {
    loadCSS('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');
    return () => {
      unloadCSS();
    };
  }, []);
}
