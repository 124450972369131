import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Card, Form, Table, Alert, Modal } from 'react-bootstrap';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './Survey.css';
import LogoImg from './4sightlogo1.png';

const questions = [
  { questionText: 'Overall Performance of the DP Or Scripting team' },
  { questionText: 'Quality of the Script' },
  { questionText: 'Timelines of the Script Delivery' },
  { questionText: 'Quality of the Data Tables' },
  { questionText: 'Timelines of the Data Tables Delivery' },
  { questionText: "DP/Scripter's Quality of Communication and Explanation" },
  { questionText: "Availability and Support" },
  { questionText: 'Anything Specific you would like to Highlight about the Study?', inputField: true },
];

const SurveyPerformance = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { surveyId } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [answers, setAnswers] = useState(questions.map(() => ''));
  const [surveyData, setSurveyData] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        const response = await fetch(`https://4sightoperations.in/survey/survey/${surveyId}/`,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('Token')}`,
          },
        });
        const data = await response.json();
        setSurveyData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSurveyData();
  }, [surveyId]);

  useEffect(() => {
    let filtered = [];
    if (surveyData.scripting_in_charge !== 'Not Applicable' || surveyData.dp_in_charge !== 'Not Applicable') {
      filtered = questions.filter((question) => {
        if (question.questionText.includes('Script')) {
          return surveyData.scripting_in_charge !== 'Not Applicable';
        }
        if (question.questionText.includes('Data Tables')) {
          return surveyData.dp_in_charge !== 'Not Applicable';
        }
        return true;
      });
    }
    setFilteredQuestions(filtered);
  }, [surveyData]);

  const handleSubmit = async () => {
    if (!surveyId) {
      console.error("Cannot submit responses. Survey ID is missing.");
      return;
    }
  
    const responses = filteredQuestions.map((question, index) => ({
      Question: `Q${index + 1}`,
      Response: answers[index] || '',
    }));
  
    try {
      // Submit survey responses
      const response = await fetch(`https://4sightoperations.in/survey/survey/${surveyId}/submit-responses/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('Token')}`,
        },
        body: JSON.stringify({ responses }),
      });
  
      if (!response.ok) throw new Error('Network response was not OK');
  
      // Update feedback_survey_done status in the segments database
      const segmentUpdateResponse = await fetch('https://4sightoperations.in/api/segments/update-feedback-status/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('Token')}`,
        },
        body: JSON.stringify({
          segment_id: surveyData.segment_id, // Assuming `surveyData` includes `segment_id`
          feedback_survey_done: 'Yes',
        }),
      });
  
      if (!segmentUpdateResponse.ok) throw new Error('Failed to update segment feedback status.');
  
      setShowScore(true); // Show thank-you message if both calls are successful
    } catch (error) {
      console.error('Error in Submitting:', error);
    }
  };
  

  const handleNextQuestion = () => {
    if (!answers[currentQuestion]) {
      setShowAlert(true); // Show an alert if the current question is unanswered
      return;
    }
    setShowAlert(false); // Hide alert if the question is answered
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < filteredQuestions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      handleSubmit(); // Submit responses if all questions are answered
    }
  };
  

  const handleAnswerOptionClick = (answer) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestion] = answer; // Update the answer
    setAnswers(updatedAnswers); // Save the updated answers
    setShowAlert(false); // Hide the alert since an answer was selected
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < filteredQuestions.length) {
      setCurrentQuestion(nextQuestion); // Move to the next question
    } else {
      handleSubmit(); // Submit responses if all questions are answered
    }
  };
  

  return (
    <Container fluid className="mt-4">
      
      {showScore ? (
        <div className="text-center my-5">
          <Card className="p-4 shadow-sm">
            <Card.Body>
              <h4>Thank You!</h4>
              <p>Your responses have been recorded.</p>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center flex-column">
          {currentStep === 0 ? (
            <Card className="p-4 shadow-sm w-75 my-5">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <img src={LogoImg} alt="Logo" style={{ width: '100px' }} />
                  <Button onClick={() => setCurrentStep(1)}>Continue</Button>
                </div>
                <Table bordered className="mt-4">
                  <tbody>
                    <tr><td>Researcher in Charge</td><td>{surveyData.researcher_in_charge}</td></tr>
                    <tr><td>Project Name</td><td>{surveyData.project_name}</td></tr>
                    <tr><td>Job Number</td><td>{surveyData.job_number}</td></tr>
                    <tr><td>Year</td><td>{surveyData.year}</td></tr>
                    <tr><td>Month</td><td>{surveyData.month}</td></tr>
                    <tr><td>Scripting in Charge</td><td>{surveyData.scripting_in_charge}</td></tr>
                    <tr><td>DP in Charge</td><td>{surveyData.dp_in_charge}</td></tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : (
            filteredQuestions.map((question, index) => (
              <Transition key={index} in={index === currentQuestion} timeout={500} classNames="cards">
<Card className="p-4 shadow-sm w-50 my-4" style={{ display: index === currentQuestion ? 'block' : 'none' }}>
  <Card.Body>
    <Card.Title className="text-center">{question.questionText}</Card.Title>
    {!question.inputField && (
      <div className="d-flex justify-content-around my-3">
        {['Excellent', 'Good', 'Average', 'Poor', 'Very Poor'].map((answer) => (
          <Button
            key={answer}
            variant={answers[currentQuestion] === answer ? 'primary' : 'outline-secondary'}
            onClick={() => handleAnswerOptionClick(answer)}
          >
            {answer}
          </Button>
        ))}
      </div>
    )}
    {question.inputField && (
      <>
        <Form.Control
          as="textarea"
          placeholder="Enter your response"
          value={answers[currentQuestion]}
          onChange={(e) => {
            const updatedAnswers = [...answers];
            updatedAnswers[currentQuestion] = e.target.value;
            setAnswers(updatedAnswers);
          }}
          className="my-3"
        />
      </>
    )}
    {showAlert && (
      <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
        Please select an answer for this question before proceeding.
      </Alert>
    )}
    <div className="d-flex justify-content-between mt-3">
      {currentQuestion > 0 && (
        <Button variant="outline-secondary" onClick={() => setCurrentQuestion(currentQuestion - 1)}>
          <FontAwesomeIcon icon={faAngleLeft} /> Back
        </Button>
      )}
<Button variant="outline-primary" onClick={handleNextQuestion} className="py-2 px-4">
  {currentQuestion < filteredQuestions.length - 1 ? 'Next' : 'Submit'} 
  <FontAwesomeIcon icon={faAngleRight} className="ml-2"/>
</Button>

    </div>
  </Card.Body>
</Card>

              </Transition>
            ))
          )}
        </div>
      )}
    </Container>
  );
};

export default SurveyPerformance;
