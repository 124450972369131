import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavigationBar.css';
import logo from '../Components/4sightlogo.png';
import AuthContext from './AuthContext';

const NavigationBar = () => {
  const { isLoggedIn, username, handleLogout } = useContext(AuthContext); // Access context values
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const onLogoutClick = () => {
    handleLogout(); // Use handleLogout from context
    navigate('/');
  };

  return (
    <div>
      <Navbar style={{ backgroundColor: "#2b3e50" }} fixed="top">
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* Logo Section */}
            <Nav.Item style={{ width: 'auto', height: '75px' }}>
              <Link to="/">
                <img
                  src={logo}
                  alt="4sightlogo"
                  className="logo"
                  style={{ width: '100px', height: '90px', transform: 'scale(1.5)' }}
                />
              </Link>
            </Nav.Item>

            {/* Title Section */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                color: 'white',
                fontSize: '1.5rem',
                paddingLeft: '20px', // Add left padding
                paddingRight: '20px', // Optional: Add some right padding to balance
              }}
            >
              <h2 className="ps operations">4Sight Operations</h2>
            </div>

            {/* Dropdown Section */}
            <Nav style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className="me-auto">
              <NavDropdown
                title={isLoggedIn ? <span className="username">Hi - {username}</span> : <span style={{ color: 'white' }}>Login</span>}
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                {!isLoggedIn && (
                  <NavDropdown.Item as={Link} to="/login">Login</NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/update-profile">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                {isLoggedIn && (
                  <NavDropdown.Item onClick={onLogoutClick}>Logout</NavDropdown.Item>
                )}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
