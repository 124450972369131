import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Toast, ToastContainer, Form, Badge } from "react-bootstrap";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../Components/JTProjectList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const JTProjectList = () => {
  const [unreadCounts, setUnreadCounts] = useState({});
  const [projectNames, setProjectNames] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
    const [cascadingNames, setCascadingNames] = useState([]);
    const email=localStorage.getItem('username')
    
  const [filters, setFilters] = useState({
    unit: "",
    year: "",
    month: "",
    projectName: "",
    jobNumber: "",
    status: "",
  });

  const navigate = useNavigate();
  const fetchUnreadCountsForProjects = async () => {
    const counts = {};
    const promises = filteredProjects.map(async (project) => {
      const unreadCount = await fetchUnreadCount(project);
      counts[project.job_number] = unreadCount; // Store unread count by job_number
    });
  
    // Wait for all promises to complete
    await Promise.all(promises);
  
    // Set the unread counts once all the counts are fetched
    setUnreadCounts(counts);
  };
  
  // useEffect(() => {
  //   fetchUnreadCountsForProjects(); // Call the function to fetch unread counts after the projects are loaded
  // }, [filteredProjects]); // Trigger the effect when filteredProjects changes

useEffect(() => {
  
    // Function to fetch cascading names based on email
    const fetchCascadingNames = async () => {
      
      try {
        // Fetch the Token from localStorage
        const token = localStorage.getItem('Token');
        if (!token) {
          throw new Error('Token not found in localStorage');
        }

        // Make the API call
        const response = await fetch(`https://4sightoperations.in/users/cascading_names/?email=${email}`, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });

        // Check if the response is ok (status 200)
        if (!response.ok) {
          throw new Error('Failed to fetch cascading names');
        }

        // Parse the response JSON
        const data = await response.json();

        // Update the state with the cascading names data
        setCascadingNames(data);
        setLoading(false);
      } catch (err) {
        // Set error state if there's an issue
        setError(err.message);
        setLoading(false);
      }
    };

    // Call the fetch function when the component mounts
    fetchCascadingNames();
  }, [email]); // Dependency array to re-run the effect when email changes
  
  // Fetch unread count for discussions
// Fetch unread count for discussions
const fetchUnreadCount = async (project) => {
  try {
    const { job_number, segment_name } = project;
    const response = await fetch(
      `https://4sightoperations.in/api/segments/fetch_unread_count?job_number=${job_number}&segment_name=${segment_name}`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${localStorage.getItem("Token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch unread count");
    }
    const data = await response.json();
    return data.unreadCount || 0; // Return unread count or 0 if not available
  } catch (error) {
    console.error("Error fetching unread count:", error);
    return 0; // Default to 0 if an error occurs
  }
};

  // Handle discussion click (show unread count and navigate)
  const handleDiscussion = async (project) => {
    try {
      // Fetch unread count and show it dynamically
      const unreadCount = await fetchUnreadCount(project);
      if (unreadCount > 0) {
        alert(`You have ${unreadCount} unread comments.`);
      }
  
      const { job_number, segment_name } = project;
      const response = await fetch(
        `https://4sightoperations.in/api/segments/fetch_segment_id_by_job_and_name?job_number=${job_number}&segment_name=${segment_name}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch segmentId");
      }
  
      const data = await response.json();
      const segmentId = data.segmentId;
  
      if (!segmentId) {
        alert("Segment ID not found!");
        return;
      }
  
    //  alert(`Fetched Segment ID: ${segmentId}`);
      navigate("/discussion", { state: { segmentId } });
    } catch (error) {
      console.error("Error fetching segment ID:", error);
      alert("An error occurred while fetching segment ID.");
    }
  };
  
  useEffect(() => {
    const fetchProjectNames = async () => {
      setLoading(true);
      setError(""); // Clear any previous error messages
   
      try {
        const cascadingNamesParam = cascadingNames.join("&name=");
        //console.log("Request URL:", `https://4sightoperations.in/api/segments/fetch-segments-by-name?name=${cascadingNamesParam}`);
   
        const response = await fetch(
          `https://4sightoperations.in/api/segments/fetch-segments-by-name?name=${cascadingNamesParam}`,
          {
            method: "GET",
            headers: {
              Authorization: `Token ${localStorage.getItem("Token")}`,
              "Content-Type": "application/json",
            },
          }
        );
   
        if (!response.ok) {
          throw new Error("Failed to fetch project names");
        }
   
        const data = await response.json();
   
        const formattedProjectNames = data.map((project) => ({
          ...project,
          actualDateOfFinalOutput: project.actual_date_of_final_output
            ? moment(project.actual_date_of_final_output).format("YYYY-MM-DD")
            : null,
          actualDateOfLink: project.actual_date_of_link
            ? moment(project.actual_date_of_link).format("YYYY-MM-DD")
            : null,
          expectedDateOfFinalOutput: project.expected_date_of_final_output
            ? moment(project.expected_date_of_final_output).format("YYYY-MM-DD")
            : null,
          expectedDateOfLink: project.expected_date_of_link
            ? moment(project.expected_date_of_link).format("YYYY-MM-DD")
            : null,
        }));
   
        setProjectNames(formattedProjectNames);
        setFilteredProjects(formattedProjectNames);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError("An error occurred while fetching projects.");
      } finally {
        setLoading(false);
      }
    };
   
    fetchProjectNames();
   }, [cascadingNames]);  // Trigger the effect whenever cascadingNames changes
   
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const filtered = projectNames.filter((project) => {
      return (
        (filters.unit === "" || project.unit?.toLowerCase().includes(filters.unit.toLowerCase())) &&
        (filters.year === "" || project.year?.toString().includes(filters.year)) &&
        (filters.month === "" || project.month?.toLowerCase().includes(filters.month.toLowerCase())) &&
        (filters.projectName === "" || project.project_name?.toLowerCase().includes(filters.projectName.toLowerCase())) &&
        (filters.jobNumber === "" || project.job_number?.toLowerCase().includes(filters.jobNumber.toLowerCase())) &&
        (filters.status === "" || project.status?.toLowerCase().includes(filters.status.toLowerCase()))
      );
    });
    setFilteredProjects(filtered);
  }, [filters, projectNames]);

  const handleEdit = (jobNumber) => {
    navigate("/edit", {
      state: {
        jobNumber,
        editMode: true,
      },
    });
  };

  const handleView = (project) => {
    navigate("/project_detailed_view", { state: { project } });
  };

  const handleCreateNew = () => {
    navigate("/create-new");
    setSuccessMessage("New project created successfully!");
    setShowSuccessToast(true); // Show success toast
  };

  return (
    <div>
      <div className="tableEffect" style={{ width: "95%", margin: "auto" }}>
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <Button variant="primary" onClick={handleCreateNew}>
            + Create New Project
          </Button>
        </div>

        <div className="filters-container" style={{ marginBottom: "20px" }}>
          <Form>
            <div className="row">
              <div className="col-md-2">
                <Form.Control
                  type="text"
                  placeholder="Year"
                  name="year"
                  value={filters.year}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  type="text"
                  placeholder="Month"
                  name="month"
                  value={filters.month}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  type="text"
                  placeholder="Project Name"
                  name="projectName"
                  value={filters.projectName}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  type="text"
                  placeholder="Job Number"
                  name="jobNumber"
                  value={filters.jobNumber}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <Form.Control
                  type="text"
                  placeholder="Status"
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
          </Form>
        </div>

        {loading ? (
          <div className="loading-container">
            <Spinner animation="border" variant="primary" />
            <p>Loading projects...</p>
          </div>
        ) : error ? (
          <div className="error-container">{error}</div>
        ) : filteredProjects.length === 0 ? (
          <div className="empty-container">No projects found.</div>
        ) : (
          <div className="responsive-table">
            <Table bordered hover className="project-list-table">
              <thead>
                <tr>
                  <th>UNIT</th>
                  <th>YEAR</th>
                  <th>MONTH</th>
                  <th>PROJECT NAME</th>
                  <th>JOB NUMBER</th>
                  <th>SEGMENT NUMBER</th>
                  <th>SEGMENT NAME</th>
                  <th>CONTACT PERSON (Research)</th>
                  <th>CONTACT PERSON (Script)</th>
                  <th>EXPECTED FINAL LINK</th>
                  <th>ACTUAL FINAL LINK</th>
                  <th>CONTACT PERSON (DP)</th>
                  <th>EXPECTED DATE OF FINAL OUTPUT</th>
                  <th>ACTUAL DATE OF FINAL OUTPUT</th>
                  <th>STATUS</th>
                  <th>VIEW</th>
                  <th>EDIT</th>
                  <th>DISCUSSION</th>
                </tr>
              </thead>
              <tbody>
                {filteredProjects.map((project, index) => {
                  const finalOutputStyle =
                    project.actualDateOfFinalOutput && project.expectedDateOfFinalOutput
                      ? moment(project.actualDateOfFinalOutput).isAfter(project.expectedDateOfFinalOutput)
                        ? { color: "red" }
                        : { color: "green" }
                      : {};

                  const finalLinkStyle =
                    project.actualDateOfLink && project.expectedDateOfLink
                      ? moment(project.actualDateOfLink).isAfter(project.expectedDateOfLink)
                        ? { color: "red" }
                        : { color: "green" }
                      : {};

                  return (
                    <tr key={index}>
                      <td>{project.unit || "-"}</td>
                      <td>{project.year || "-"}</td>
                      <td>{project.month || "-"}</td>
                      <td>{project.project_name || "-"}</td>
                      <td>{project.job_number || "-"}</td>
                      <td>{project.no_of_segments || "-"}</td>
                      <td>{project.segment_name || "-"}</td>
                      <td>{project.contact_person_in_research || "-"}</td>
                      <td>{project.contact_person_for_scripting || "-"}</td>
                      <td style={finalLinkStyle}>{project.expectedDateOfLink || "-"}</td>
                      <td style={finalLinkStyle}>{project.actualDateOfLink || "-"}</td>
                      <td>{project.contact_person_for_dp || "-"}</td>
                      <td style={finalOutputStyle}>{project.expectedDateOfFinalOutput || "-"}</td>
                      <td style={finalOutputStyle}>{project.actualDateOfFinalOutput || "-"}</td>
                      <td>{project.status || "-"}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={faEye}
                          className="icon-button"
                          onClick={() => handleView(project)}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="icon-button"
                          onClick={() => handleEdit(project.job_number)}
                        />
                      </td>
                      <td>
                      <FontAwesomeIcon
                        icon={faComments}
                        className="icon-button"
                        onClick={() => handleDiscussion(project)}
                      />
                      <Badge pill bg="danger" className="ml-2">
                        {project.unread_count || 0} {/* Display dynamic unread count */}
                      </Badge>
                    </td>


                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          onClose={() => setShowSuccessToast(false)}
          show={showSuccessToast}
          delay={3000}
          autohide
          bg="success"
        >
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>{successMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default JTProjectList;
