import React, { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AiOutlineHome, AiOutlineTable, AiOutlineFileText } from 'react-icons/ai';
import FilterModal from './FilterModal';
import './JTSide.css';

const JTSide = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [showFilterModal, setShowFilterModal] = useState(false);
  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  // Replace this with actual logic for getting the user's email
  const userEmail = localStorage.getItem('username'); // Assuming the user's email is stored in localStorage
  const isRamUser = userEmail === 'ram@4sight-global.com';

  return (
    <Container fluid className="jt-sidebar-container">
      <div className="sidebar">
        <Nav defaultActiveKey="/" className="flex-column">
          {/* Home Navigation */}
          <div className="nav-link-container">
            <Nav.Link
              href="/projects_list"
              className={`nav-link ${currentPath.startsWith('/projects_list') ? 'active' : ''}`}
            >
              <AiOutlineHome className="icon" />
              <span>Home</span>
            </Nav.Link>
          </div>

          <div className="nav-link-container">
            <Nav.Link
              href="/projects_list"
              className={`nav-link ${currentPath.startsWith('/projects_list') ? 'active' : ''}`}
            >
              <AiOutlineHome className="icon" />
              <span>Home</span>
            </Nav.Link>
          </div>

          {/* Create New Navigation */}
          <div className="nav-link-container">
            <Nav.Link
              href="/create-new"
              className={`nav-link ${currentPath.startsWith('/create-new') ? 'active' : ''}`}
            >
              <AiOutlineTable className="icon" />
              <span>Create New</span>
            </Nav.Link>
          </div>

          {/* Reports Navigation */}
          <div className="nav-link-container">
            <Nav.Link
              href="/project_summary"
              className={`nav-link ${currentPath.startsWith('/project_summary') ? 'active' : ''}`}
            >
              <AiOutlineFileText className="icon" />
              <span>Reports</span>
            </Nav.Link>
          </div>

          {/* Researchers Feedback - Only for Ram */}
          {isRamUser && (
            <div className="nav-link-container">
              <Nav.Link
                href="/survey_insights"
                className={`nav-link ${currentPath.startsWith('/survey_insights') ? 'active' : ''}`}
              >
                <AiOutlineFileText className="icon" />
                <span>Researchers Feedback</span>
              </Nav.Link>
            </div>
          )}
        </Nav>
      </div>
      <FilterModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </Container>
  );
};

export default JTSide;
