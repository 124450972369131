import React, { useState, useEffect } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Timesheet.css';
import axios from 'axios';
import { useBootstrap } from './useBootstrap';
import  Select from 'react-select';
const localizer = momentLocalizer(moment);

const taskOptionsStatic = [
  { value: "scripting_questionnaire_review", label: "Scripting - Questionnaire review" },
  { value: "scripting_standard_templates", label: "Scripting - Standard Templates (which includes template preparation, simple logics, setting up variable names, test & live link creations)" },
  { value: "scripting_scripting_logics", label: "Scripting - Scripting logics (this includes complex logics, like looping, max-diff, product rotation logics using external file)" },
  { value: "scripting_updating_feedbacks", label: "Scripting - Updating feedbacks (feedback from researchers, feedback from co-scripter, etc)" },
  { value: "scripting_rework_1", label: "Scripting - Rework 1 (Questionnaire revision, revised logics, Issues from research end)" },
  { value: "scripting_rework_2", label: "Scripting - Rework 2 (Issues from Scripting end)" },
  { value: "scripting_follow_up", label: "Scripting - Follow up (Technical challenges & following up with S2C & S2G, Technical discussions(discussions on logics, platform issue))" },
  { value: "scripting_others", label: "Scripting - Others" },
  { value: "quality_checks_qc_survey", label: "Quality checks - QC the survey" },
  { value: "quality_checks_qc_co_scripter", label: "Quality checks - QC other survey from co-scripter" },
  { value: "quality_checks_others", label: "Quality checks - others" },
  { value: "raw_data_extract_share", label: "Raw Data - Extracting and sharing the data with Researchers" },
  { value: "raw_data_deletion", label: "Raw Data - Data deletion with respect to QC report from researchers" },
  { value: "raw_data_checking", label: "Raw Data - Data checking" },
  { value: "raw_data_preparation", label: "Raw data - Data preparation (like DPCX)" },
  { value: "raw_data_merging", label: "Raw data - Raw data merging (preparing syntax for raw data merging, merging data from multiple platforms, tracker data merging)" },
  { value: "raw_data_others", label: "Raw data - others" },
  { value: "oe_data_preparation", label: "OE Data - Preparing OE data for coding, Verbatim preparation for researchers" },
  { value: "oe_data_conversion", label: "OE Data - Converting OE data into SPSS format" },
  { value: "oe_data_costing", label: "OE Data - Calculating OE Costing" },
  { value: "oe_data_others", label: "OE Data - others" },
  { value: "database_cleaning", label: "Database - Cleaning the database (Formatting the mobile number, validating email links, etc)" },
  { value: "database_unique_links", label: "Database - Creating unique links" },
  { value: "database_others", label: "Database - others" },
  { value: "quota_setup", label: "Quota - Setting up the quota and updating the quota for tracker" },
  { value: "quota_monitoring", label: "Quota - Monitoring quota & preparing the quota sheet for the completes" },
  { value: "quota_rework_1", label: "Quota - Rework 1 (Because of quota issues, Because of multiple versions from research end)" },
  { value: "quota_rework_2", label: "Quota - Rework 2 (Because of quota issues at scripting end)" },
  { value: "quota_others", label: "Quota - others" },
  { value: "dashboard_hsbc", label: "Dashboard - (HSBC Dashboards)" },
  { value: "general_meeting_fun", label: "General - (Meeting, Fun activities, celebrations, etc)" },
  { value: "up_skilling_powerbi", label: "Upskilling - (Power BI, Python, SPSS, Dimensions, etc)" },
  { value: "up_skilling_communication", label: "Upskilling - (Communication related)" },
  { value: "email_invitation_preparation", label: "Email Invitation Preparation" },
  { value: "discussion_xebo", label: "Discussion with XEBO regarding issues" },
  { value: "scripting_translations", label: "Scripting - Translations" },
  { value: "survey_launching", label: "Survey Launching process" },
  { value: "quota_issues", label: "Quota issues (including discussion)" },
  { value: "template_logos", label: "Template - Logos" },
  { value: "shared_sample_data", label: "Shared sample data" },
  { value: "triggered_email_sms_reminders", label: "Triggered Email / SMS Reminders" },
  { value: "sms_trigger", label: "SMS Trigger" },
  { value: "email_trigger", label: "Email Trigger" },
  { value: "logic_issues", label: "Logic issues" },
  { value: "dp_template", label: "DP Tables template" },
  { value: "status_update", label: "Status update" },
  { value: "norms", label: "Norms" },
  { value: "na", label: "Not Applicable" }
];

function Timesheet() {
  useBootstrap();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refreshEvents, setRefreshEvents] = useState(false);

  const [events, setEvents] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTask, setSelectedTask] = useState('');

  // Fetch data on component mount
  useEffect(() => {
    const token = localStorage.getItem("Token");
  
    axios
      .get('https://4sightoperations.in/api/segments/unique_segments_with_job/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.unique_segment_names?.length > 0) {
          // Filter out null or empty values
          const validSegments = response.data.unique_segment_names.filter((name) => name !== null && name.trim() !== "");
          setProjectOptions(validSegments);
        } else {
          console.error("No unique segment names found");
          setProjectOptions([]);
        }
      })
      .catch((error) => console.error('Error fetching unique segment names:', error));
  }, []);
  
  const handleSelectSlot = ({ start, end }) => {
    const startLocal = moment(start).local();  // Convert to local time
    const endLocal = moment(end).local();  // Convert to local time
  
    // Convert the times to UTC before sending to the backend
    const startUTC = startLocal.utc().toISOString();
    const endUTC = endLocal.utc().toISOString();
  
    console.log("Start time in UTC:", startUTC); // Log UTC time
    console.log("End time in UTC:", endUTC); // Log UTC time
    
    // Prepare time slots and dates
    const dates = [];
    const times = [];
    
    let currentDate = startLocal.clone();
    while (currentDate <= endLocal) {
      dates.push(currentDate.clone());  // Use clone to avoid reference issues
      currentDate.add(1, 'day');
    }
    setSelectedDates(dates);
  
    let currentTime = startLocal.clone();
    while (currentTime < endLocal) {
      // Create the time slot and store it in the `times` array
      times.push({
        start: currentTime.clone(),
        end: currentTime.clone().add(30, 'minutes'), // Increment by 30 minutes
      });
      currentTime.add(30, 'minutes'); // Move the time forward by 30 minutes
    }
  
    setSelectedTimeSlots(times);
    setShowAddModal(true);
  };
  
  

  const handleAddModalClose = () => {
   // alert("XX")
   setShowAddModal(false);
   setSelectedDates([]);
   setSelectedTimeSlots([]);
   setSelectedProject('');
   setSelectedTask('');
   setRefreshEvents((prev) => !prev);  
  };
  

  useEffect(() => {
    const token = localStorage.getItem("Token");
  
    axios
      .get("https://4sightoperations.in/timesheet/api/events/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          let cumulativeSum = 0; // Initialize cumulative sum
          const formattedEvents = response.data.flatMap((event) =>
            event.time_slots.map((slot, index) => {
              // Calculate duration in minutes
              const duration =
                moment.utc(slot.end_time).diff(moment.utc(slot.start_time), "minutes");
              cumulativeSum += duration;
  
              return {
                id: event.id,
                title: `${event.project || ""}${
                  event.task ? ", " + event.task : ""
                } (Cumulative: ${cumulativeSum} mins)`,
                start: moment.utc(slot.start_time).local().toDate(), // Convert to local time
                end: moment.utc(slot.end_time).local().toDate(), // Convert to local time
                cumulativeSum, // Add cumulative sum for potential future use
              };
            })
          );
          setEvents(formattedEvents);
        } else {
          console.warn("No events found");
        }
      })
      .catch((error) => console.error("Error fetching events:", error));
  }, [refreshEvents]);
  
  const handleAddModalSubmit = () => {
  // Validate mandatory fields
  if (!selectedProject) {
    alert("Please select a project!");
    return;
  }

  if (!selectedTask) {
    alert("Please select a task!");
    return;
  }

  if (selectedDates.length === 0 || selectedTimeSlots.length === 0) {
    alert("Please select at least one date and time slot!");
    return;
  }

  const token = localStorage.getItem("Token");

  if (!token) {
    console.error("Token not found");
    return;
  }

  // Extract task as a string (use 'value' if 'selectedTask' is an object)
  const taskString = selectedTask ? selectedTask.value : null;

  // Format the time slots properly
  const timeSlots = selectedTimeSlots.map((slot) => ({
    start_time: slot.start.toISOString(),
    end_time: slot.end.toISOString(),
  }));

  // Make the API request
  axios
    .post(
      "https://4sightoperations.in/timesheet/api/events/",
      {
        project: selectedProject,
        task: taskString, // Pass taskString (not the whole object)
        start_date: moment(selectedDates[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDates[selectedDates.length - 1]).format("YYYY-MM-DD"),
        time_slots: timeSlots,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    )
    .then((response) => {
      if (response.data && response.data.id) {
        // Map the newly created time slots to events and append them to the state
        const newEvents = timeSlots.map((slot) => ({
          id: response.data.id, // Use the ID returned from the backend
          title: `${selectedProject || ""}${taskString ? ", " + taskString : ""}`,
          start: moment.utc(slot.start_time).local().toDate(),
          end: moment.utc(slot.end_time).local().toDate(),
        }));

        // Group events by title
        const groupedEvents = newEvents.reduce((acc, event) => {
          if (!acc[event.title]) {
            acc[event.title] = [];
          }
          acc[event.title].push(event);
          return acc;
        }, {});

        // Update the `events` state with the grouped events
        setEvents((prevEvents) => {
          const groupedPrevEvents = prevEvents.reduce((acc, event) => {
            if (!acc[event.title]) {
              acc[event.title] = [];
            }
            acc[event.title].push(event);
            return acc;
          }, {});

          // Merge the new grouped events with previous ones
          return [
            ...Object.values(groupedPrevEvents).flat(),
            ...Object.values(groupedEvents).flat(),
          ];
        });

        // Close the modal after submission
      } else {
        console.error("Failed to create event: Invalid response", response);
      }
      handleAddModalClose();
    })
    .catch((error) => {
      console.error("Error creating event:", error);
      alert("There was an error creating the event. Please try again.");
    });
};

  

  
  const [deletedEvent, setDeletedEvent] = useState(null);

  const handleDeleteEvent = () => {
    const eventToDelete = selectedEvent;

    axios
      .delete(`https://4sightoperations.in/timesheet/api/events/${eventToDelete.id}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("Token")}`,
        },
      })
      .then(() => {
        // Temporarily store the deleted event
        setDeletedEvent(eventToDelete);
        setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventToDelete.id));

        // Trigger a refresh by updating the refreshEvents state
        setRefreshEvents((prev) => !prev);
      })
      .catch((error) => {
        console.error("Error deleting event:", error);
        alert("Failed to delete event. Please try again.");
      });
};

// Undo deletion
const handleUndoDelete = () => {
if (deletedEvent) {
  axios
    .post(
      "https://4sightoperations.in/timesheet/api/events/",
      {
        project: deletedEvent.project,
        task: deletedEvent.task,
        start_date: moment(deletedEvent.start).format("YYYY-MM-DD"),
        end_date: moment(deletedEvent.end).format("YYYY-MM-DD"),
        time_slots: [
          {
            start_time: moment(deletedEvent.start).toISOString(),
            end_time: moment(deletedEvent.end).toISOString(),
          },
        ],
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("Token")}`,
        },
      }
    )
    .then((response) => {
      setEvents((prevEvents) => [...prevEvents, response.data]);
      setDeletedEvent(null);
      alert("Event restored successfully.");
    })
    .catch((error) => {
      console.error("Error restoring event:", error);
      alert("Failed to restore event. Please try again.");
    });
}
};
const handleChange = (selectedOption) => {
  setSelectedTask(selectedOption);
};
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    const [project, task] = event.title.split(', ');
    setSelectedProject(project || '');
    setSelectedTask(task || '');
    setShowEditModal(true);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setSelectedEvent(null);
    setSelectedProject('');
    setSelectedTask('');
  };

  return (
    <div>
      <div style={{ height: '100vh' }}>
      <BigCalendar
    localizer={localizer}
    events={events}
    startAccessor="start"
    endAccessor="end"
    defaultView="agenda" // Ensure the Agenda view is enabled
    selectable
    onSelectSlot={handleSelectSlot}
    onSelectEvent={handleEventClick}
    step={30}
    timeslots={1}
    components={{
    agenda: {
      event: ({ event }) => (
        <span>
          {event.title} - {event.cumulativeSum} mins
        </span>
      ),
    },
  }}
/>

  
        {/* Add Modal */}
        {showAddModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add New Event</h5>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleAddModalClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <label>Project Name:</label>
                  <select
  className="form-control mb-2"
  value={selectedProject}
  onChange={(e) => setSelectedProject(e.target.value)}
>
  <option value="" hidden>
    Select Project
  </option>
  {projectOptions.length > 0 ? (
    projectOptions
      .map((option,index) => (
        <option key={`${option}-${index}`}  value={option}>
          {option}
        </option>
      ))
  ) : (
    <option value="NoProjects">No Projects Available</option>
  )}
  {!projectOptions.includes("Others") && (
    <option key="Others" value="Others">
      Others
    </option>
  )}
</select>

  
                  <label>Task Name:</label>
                  

              <Select
                      className="mb-2"
                      value={selectedTask}
                      onChange={handleChange}
                      options={taskOptionsStatic}
                      placeholder="Search Task"
                      isSearchable
                    />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleAddModalSubmit}
                  >
                    Create Event
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleAddModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
  
        {/* Edit Modal */}
        {showEditModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Delete Event</h5>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleEditModalClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                
                <div className="modal-footer">
                 
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Event
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleEditModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
  
        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ marginTop: '150px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Delete</h5>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this event? This action cannot be undone.</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      handleDeleteEvent();
                      setShowDeleteModal(false);
                      setShowEditModal(false);
                    }}
                  >
                    Confirm Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  

}

export default Timesheet;