import React, { useState, useEffect } from "react";
import { Table, Spinner, Alert } from "react-bootstrap";
import axios from "axios";

const CummulativeSummaryTable = () => {
  const [summaryData, setSummaryData] = useState(null); // Store summary data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch cumulative summary data on mount
  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://4sightoperations.in/api/segments/fetch_cumulative_summary/", {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
          },
        });
        setSummaryData(response.data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch summary data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchSummaryData();
  }, []);

  return (
    <>
      {loading && (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading...</p>
        </div>
      )}
      {error && (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      )}
      {!loading && !error && summaryData && (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Executive</th>
              <th colSpan="2" className="text-center">Script</th>
              <th colSpan="2" className="text-center">DP</th>
            </tr>
            <tr>
              <th></th>
              <th>Open</th>
              <th>Close</th>
              <th>Open</th>
              <th>Close</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys({
              ...summaryData.dp_summary,
              ...summaryData.scripting_summary,
            }).map((executive) => {
              const scriptData = summaryData.scripting_summary[executive] || {
                Open: 0,
                Closed: 0,
              };
              const dpData = summaryData.dp_summary[executive] || {
                Open: 0,
                Closed: 0,
              };

              return (
                <tr key={executive}>
                  <td>{executive}</td>
                  <td>{scriptData.Open}</td>
                  <td>{scriptData.Closed}</td>
                  <td>{dpData.Open}</td>
                  <td>{dpData.Closed}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {!loading && !error && !summaryData && (
        <Alert variant="info">No summary data available.</Alert>
      )}
    </>
  );
};

export default CummulativeSummaryTable;
