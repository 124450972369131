import React from 'react';
import { Card, Table } from 'react-bootstrap';

const NormsProjectSummaryCard = ({ summaryData }) => {
  const fieldsToIgnore = ['respondentid', 'brand', 'variant', 'productformat'];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Card style={{ width: '90%', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <Card.Header style={{ backgroundColor: '#007bff', color: 'white', fontSize: '18px' }}>
          Summary Data
        </Card.Header>
        <Card.Body>
          <h5>Total Records: {summaryData.total_records}</h5>
          {Object.entries(summaryData.fields_summary)
            .filter(([field]) => !fieldsToIgnore.includes(field)) // Exclude ignored fields
            .map(([field, details]) => (
              <Card className="mb-3" key={field}>
                <Card.Header>
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' ')}
                </Card.Header>
                <Card.Body>
                  {details.mapped_counts ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Value</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(details.mapped_counts)
                          .filter(([key]) => key !== "") // Ignore blank entries
                          .map(([key, value]) => (
                            <tr key={key}>
                              <td>{key}</td>
                              <td>{value}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <td>Unique Count</td>
                          <td>{details.unique_count}</td>
                        </tr>
                        {details.total && (
                          <tr>
                            <td>Total</td>
                            <td>{details.total}</td>
                          </tr>
                        )}
                        {details.min_value && (
                          <tr>
                            <td>Minimum Value</td>
                            <td>{details.min_value}</td>
                          </tr>
                        )}
                        {details.max_value && (
                          <tr>
                            <td>Maximum Value</td>
                            <td>{details.max_value}</td>
                          </tr>
                        )}
                        {details.average_value && (
                          <tr>
                            <td>Average Value</td>
                            <td>{details.average_value}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                </Card.Body>
              </Card>
            ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default NormsProjectSummaryCard;
